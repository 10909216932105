<template>
  <div class="container">
    <div class="orderPay">
      <div class="orderPay-title"><h3>订单结算</h3></div>
      <div class="orderPay-tips">
        订单已提交成功，请在
        <a-statistic-countdown
          :value="deadline"
          format="m 分 s 秒"
          @finish="onFinish"
        />
        内完成支付，负责订单将被自动关闭！
      </div>

      <div class="orderPay-title"><span>订单详情</span></div>

      <div class="orderPay-details">
        <div class="details-top">
          <span>下单时间：{{ orderInfo.createTime }}</span>
          <span style="flex: 1;">订单编号：{{ orderInfo.orderSn }}</span>
          <div class="details-num">共 {{ goodsList.length }} 件商品</div>
        </div>
        <div class="details-center">
          <div v-for="(item, index) in goodsList" :key="item.id" class="details-center-items">
            <div class="img-box"><img v-lazy="item.skuPic" alt="goods"></div>
            <div class="details-info">
              <span>{{ index + 1 }}. {{ item.skuName }}</span>
            </div>
          </div>

        </div>
        <div class="details-bottom">
          <span>待付金额：</span>
          <span>¥ {{ orderInfo.payAmount }}</span>
        </div>
      </div>

      <div class="orderPay-title"><span>选择付款方式</span></div>
      <div class="pay-methods">
        <a-radio-group v-model:value="value">
          <a-radio :value="1">微信支付</a-radio>
          <a-radio :value="2">支付宝支付</a-radio>
          <a-radio :value="3">银联支付</a-radio>
          <a-radio :value="4">线下对公支付</a-radio>
        </a-radio-group>
      </div>

      <div class="pay-fun">
        <a-button @click="confirmPay">确认</a-button>
        <a-button @click="backCart">返回购物车</a-button>
      </div>
    </div>
    <div ref="alipayWap" v-html="alipay" />
  </div>
</template>

<script>
import { payingOrder, payOrder } from '@/api/order'
// import axios from 'axios'
// import Cookie from 'js-cookie'
export default {
  name: 'OrderPay',
  components: {
  },
  data() {
    return {
      deadline: null,
      value: 1,
      goodsList: [],
      orderInfo: {},
      alipay: ''
    }
  },
  created() {
    if (this.$route.query.orderId) {
      this.getOrderInfo(this.$route.query.orderId)
    }
    // this.orderInfo = JSON.parse(this.$route.query.order)
    // this.goodsList = JSON.parse(this.$route.query.orderItem)
    // //  countDown
    // const countDown = 1000 * 60 * 15 - (Date.now() - new Date(this.orderInfo.createTime))
    // this.deadline = Date.now(this.orderInfo.createTime) + countDown
  },
  methods: {
    backCart() {
      this.$router.replace({ path: '/car' })
    },
    onFinish() {
      this.$message.error('订单超时', 1.5, _ => {
        this.$router.go(-1)
      })
    },
    confirmPay() {
      if (!this.orderInfo.orderSn) {
        this.$message.error('订单错误')
        return
      }
      if (this.value === 2) {
        this.orderPayFun(this.orderInfo.orderSn)
      }
      // this.$router.push({ path: '/order/paySuccess' })
    },
    getOrderInfo(id) {
      const data = {
        orderId: id
      }
      payingOrder(data).then(res => {
        // console.log(res)
        // this.goodsList = res.data
        this.orderInfo = res.submitOrderResp.order
        this.goodsList = res.submitOrderResp.orderItems
        // //  countDown
        const countDown = 1000 * 60 * 15 - (Date.now() - new Date(this.orderInfo.createTime))
        this.deadline = Date.now(this.orderInfo.createTime) + countDown
      })
    },
    orderPayFun(orderSn) {
      const data = {
        orderSn: orderSn
      }
      payOrder(data).then(res => {
        // this.alipay = res
        // this.$nextTick(() => {
        //   this.$refs.alipayWap.children[0].submit()
        // })

        // const dwSafari = window.open()
        // dwSafari.document.open()
        // const html = res.replace(/[^\u0000-\u00FF]/g, function($0) {
        //   return escape($0).replace(/($u)(\w{4})/gi, '&#x$2;')
        // })
        // dwSafari.document.write('<html><head><title></title><meta charset="utf-8"><body>' + html + '</body></head></html>')
        // dwSafari.document.forms[0].submit()
        // dwSafari.document.close()

        const paydata = this.$router.resolve({ path: '/order/pay', query: {
          htmlData: res
        }})
        window.open(paydata.href, '_blank')
      })
    }
  }
}
</script>
<style lang='less' scoped>
.orderPay {
  padding: 64px 0 146px;

  .orderPay-title {
    margin-bottom: 24px;

    & > h3 {
      margin: 0;
      color: #333333;
      font-weight: bold;
      font-size: 36px;
    }

    & > span {
      color: #333333;
      font-weight: bold;
      font-size: 20px;
    }
  }
  .orderPay-tips {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-bottom: 62px;
  }

  .orderPay-details {
    width: 100%;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
    margin-bottom: 64px;

    .details-top {
      width: 100%;
      height: 69px;
      background-color: #F8F8F8;
      padding: 24px 72px;
      display: flex;
      align-items: center;

      & > span {
        font-size: 16px;
        color: #666666;
        font-weight: 400;
        line-height: 16px;
      }
      & > span:first-child {
        margin-right: 72px;
      }
      .details-num {
        color: #333333;
        font-size: 20px;
        font-weight: bold;
      }
    }
    .details-bottom {
      width: 100%;
      height: 69px;
      background-color: #F8F8F8;
      padding: 19px 72px;
      text-align: right;

      & > span:first-child {
        color: #333333;
        font-size: 16px;
        font-weight: bold;
        margin-right: 48px;
        line-height: 31px;
      }
      & > span:last-child {
        color: #D40000;
        font-size: 24px;
        font-weight: bold;
        line-height: 31px;
      }
    }

    .details-center {
      width: 100%;
      min-height: 175px;
      padding: 24px 72px;

      .details-center-items {
        display: flex;
        align-items: center;
      }

      .img-box {
        width: 88px;
        height: 88px;
        margin-right: 24px;

        & > img {
          width: 100%;
          height: 100%;
        }
      }

      .details-info {
        flex: 1;

        & > span {
          color: #666666;
          font-weight: 400;
          display: block;
          margin-bottom: 8px;
        }
      }

    }
  }
  .pay-methods {
    margin-bottom: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-radio-wrapper {
      margin-right: 170px;
    }
  }
  .pay-fun {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    & > button {
      width: 173px;
      height: 46px;
      opacity: 1;
      border-radius: 32px;
      font-size: 16px;
      text-align: center;
      line-height: 16px;
    }
    & > button:first-child {
      background: #3B6652;
      color: #FFFFFF;
      margin-right: 73px;
    }
    & > button:last-child {
      border: 1px solid #E5E5E5;
      color: #999999;
      margin-left: 73px;
    }
  }
}

:deep(.ant-statistic-content) {
  color: #D40000;
  font-size: 18px;
  margin: 0 4px;
}
</style>
